import { useEffect, useState } from "react";
import UnityGame from "./UnityGame";
import Pages from "./pages";

import LoadingPage from "./pages/LoadingPage";
import TelemetryHelper from "./utils/TelemetryHelper";
import ComingSoonPage from "./components/ComingSoonPage";
import Login from "./pages/Login";
import CookieBanner from "./components/CookieBanner";

const show_coming_soon_page = process.env.REACT_APP_SHOW_COMING_SOON_PAGE === "true";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [unity, setUnity] = useState(false);
  const [showComingSoonPage, setShowComingSoonPage] = useState<boolean>(show_coming_soon_page);
  const [logout, setLogout] = useState<boolean>(false);

  const getSearchParameter = (key: string) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
  };

  useEffect(() => {
    const init = async () => {
      const game = getSearchParameter("game");
      const logoutParam = getSearchParameter("logout");
      const startParam = getSearchParameter("start");
      const loginParam = getSearchParameter("login");

      if (startParam && startParam === "true") {
        setShowComingSoonPage(false);
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, "", "/");
      }

      if (game === "true") {
        TelemetryHelper.event({
          category: "Homepage",
          action: "Redirection",
          label: "Login Redirect",
        });

        setLoading(true);
        setUnity(true);
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, "", "/");
      }
      if (loginParam === "true") {
        setShowLoginPage(true);
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, "", "/");
      }
      if (logoutParam === "true") {
        setLogout(true);
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, "", "/");
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!unity) setLoading(false);
  }, [unity]);

  const handlePlayAsAGuest = () => {
    document.cookie = "CognitoIdentityServiceProvider=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();

    TelemetryHelper.event({
      category: "Homepage",
      action: "Click",
      label: "Play as Guest",
    });
    setLogout(true);
    setLoading(true);
    setUnity(true);
  };

  const handlePlay = () => {
    TelemetryHelper.event({
      category: "Homepage",
      action: "Click",
      label: "Play Now",
    });
    setShowLoginPage(true);
  };

  // Telemetry tracking init
  useEffect(() => {
    TelemetryHelper.init();
    TelemetryHelper.send({ hitType: "SinglePage", page: "/", title: "App" });
  }, []);

  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh();

    window.addEventListener("resize", setVh);

    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  return (
    <>
      <CookieBanner />
      {showComingSoonPage && <ComingSoonPage />}
      {
        <Login
          setShowLoginPage={setShowLoginPage}
          showLoginPage={showLoginPage}
          setLoading={setLoading}
          setUnity={setUnity}
          logout={logout}
        />
      }
      {!showComingSoonPage && (
        <>
          {loading && <LoadingPage />}
          {unity && <UnityGame setLoading={setLoading} setUnity={setUnity} />}
          {!loading && !unity && <Pages handlePlay={handlePlay} handlePlayAsAGuest={handlePlayAsAGuest} />}
        </>
      )}
    </>
  );
};

export default App;
