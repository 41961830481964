import { useState } from "react";

const Legends = () => {
  const [legendActive, setLegendActive] = useState<number | null>(null);

  const legends = [
    { id: 1, image: "images/player_1.jpg", name: "Tracy Austin" },
    { id: 2, image: "images/player_2.jpg", name: "Leander Paes" },
    { id: 3, image: "images/player_3.jpg", name: "Gigi Fernandez" },
    { id: 4, image: "images/player_4.jpg", name: "Stan Smith" },
    { id: 5, image: "images/player_5.jpg", name: "Michael Chang" },
  ];

  return (
    <div className="SectionThreeLegends">
      {legends.map((legend) => (
        <div
          key={legend.id}
          onClick={() => setLegendActive(legend.id)}
          onMouseEnter={() => setLegendActive(legend.id)}
          onMouseLeave={() => setLegendActive(null)}
          className={`item-legends ${legend.id === legendActive ? "active" : ""}`}
        >
          <img src={legend.image} alt="" />
          <div className="name" style={{ backgroundImage: `url(/images/bg-item-legends.png)` }}>
            {legend.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Legends;
