import { useTranslation } from "react-i18next";

import Button from "../../../components/Button";

import "./index.sass";
import Legends from "../../../components/Legends";

interface ISection {
  handlePlay: () => void;
}

const Section = ({ handlePlay }: ISection) => {
  const { i18n } = useTranslation();

  return (
    <div className="SectionThree">
      <div className="body">
        <div className="subbody">
          <div className="global-label">{i18n.t("homepage.seccion_3.label")}</div>
          <div className="global-title">{i18n.t("homepage.seccion_3.title")}</div>
          <div className="global-subtitle">{i18n.t("homepage.seccion_3.subtitle")}</div>
        </div>
        <div className="SectionThree-btn">
          <Button onClick={handlePlay}>{i18n.t("homepage.seccion_3.play_btn")}</Button>
        </div>
        <Legends />
      </div>
    </div>
  );
};

export default Section;
