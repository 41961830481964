import { useEffect, useState } from "react";
import TelemetryHelper from "../../utils/TelemetryHelper";
import "./index.css";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("isp-ithf-cookie");
    if (cookieConsent !== "true") {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("isp-ithf-cookie", "true");
    TelemetryHelper.init();
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div id="hs-eu-cookie-confirmation" className="hs-cookie-notification-position-bottom">
          <div id="hs-eu-cookie-confirmation-inner">
            <div id="hs-eu-policy-wording">
              <p>
                This website stores cookies on your computer. These cookies are used to collect information about how you interact with our
                website and allow us to remember you. We use this information in order to improve and customize your browsing experience and
                for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we
                use, view our Privacy Policy.
              </p>
            </div>

            <div id="hs-en-cookie-confirmation-buttons-area">
              <div id="hs-eu-cookie-confirmation-button-group">
                <a onClick={handleAccept} id="hs-eu-confirmation-button" role="button">
                  Accept and Close
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
